import {Injectable} from '@angular/core';

@Injectable({
  providedIn: `root`
})
export class UserService {

  static readonly app = 'bbb';

  static setUsername(username: string) {
    localStorage.setItem(`${this.app}_username`, username);
  }

  static getUsername(): string {
    return localStorage.getItem(`${this.app}_username`);
  }

  static setRouterState(routerState: string) {
    localStorage.setItem(`${this.app}_routerLink_${this.getUsername()}`, routerState);
  }

  static getRouterState(): string {
    return localStorage.getItem(`${this.app}_routerLink_${this.getUsername()}`);
  }

}
