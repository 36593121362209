import {Component, OnInit} from '@angular/core';

import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html'
})
export class RightSidebarComponent implements OnInit {

  baseHref = environment.baseHref;

  constructor() {
  }

  ngOnInit() {
  }

}
