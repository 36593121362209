export default {
  Oops: {
    en: 'Oops...',
    vi: 'Có lỗi xảy ra...'
  },
  ServiceUnavailable: {
    en: 'Service Unavailable',
    vi: 'Lỗi kết nối, vui lòng thử lại sau'
  },
  Unauthorized: {
    en: '401 Unauthorized',
    vi: 'Phiên đăng nhập đã hết hạn'
  },
  Forbidden: {
    en: '403 Forbidden',
    vi: 'Bạn không có quyền truy cập'
  },
  NotFound: {
    en: '404 Not Found',
    vi: 'Dữ liệu không tồn tại'
  },
  SignIn: {
    en: 'Sign in',
    vi: 'Đăng nhập'
  },
  Logout: {
    en: 'Logout',
    vi: 'Đăng xuất'
  },
  ForgotPassword: {
    en: 'Forgot password?',
    vi: 'Quên mật khẩu?'
  },
  NeedHelp: {
    en: 'Need help?',
    vi: 'Cần hỗ trợ?'
  },
  Retry: {
    en: 'Retry',
    vi: 'Thử lại'
  },
  UsernameOrPasswordIncorrect: {
    en: 'Username Or Password Incorrect',
    vi: 'Tên đăng nhập hoặc mật khẩu không chính xác!'
  },
  Success: {
    en: 'Success',
    vi: 'Thành công!'
  },
  Warning: {
    en: 'Warning',
    vi: 'Cảnh báo!'
  },
  Home: {
    en: 'Home',
    vi: 'Trang chủ'
  },
  Sales: {
    en: 'Sales',
    vi: 'Bán hàng'
  },
  Purchases: {
    en: 'Purchases',
    vi: 'Mua hàng'
  },
  ThePurchases: {
    en: 'Purchases',
    vi: 'Tổng mua'
  },
  Logistics: {
    en: 'Logistics',
    vi: 'Kho vận'
  },
  Financial: {
    en: 'Financial',
    vi: 'Tài chính'
  },
  Profile: {
    en: 'Profile',
    vi: 'Thông tin cá nhân'
  },
  Dashboard: {
    en: 'Dashboard',
    vi: 'Tổng quan'
  },
  Yes: {
    en: 'Yes',
    vi: 'Có'
  },
  No: {
    en: 'No',
    vi: 'Không'
  },
  CreateOrder: {
    en: 'Create Order',
    vi: 'Tạo đơn hàng'
  },
  Order: {
    en: 'Order',
    vi: 'Đơn hàng'
  },
  Customer: {
    en: 'Customer',
    vi: 'Khách hàng'
  },
  PaidByCustomer: {
    en: 'Paid By Customer',
    vi: 'Khách hàng trả'
  },
  Cogs: {
    en: 'cogs',
    vi: 'Giá vốn'
  },
  SalesOrders: {
    en: 'Sales Orders',
    vi: 'Đơn hàng bán'
  },
  PurchasesOrders: {
    en: 'Purchases Orders',
    vi: 'Đơn hàng mua'
  },
  PurchasingOrderId: {
    en: 'Purchasing Order Id',
    vi: 'Mã phiếu mua'
  },
  ReturnsOrders: {
    en: 'Returns Orders',
    vi: 'Đơn hàng hoàn'
  },
  ReturnOrders: {
    en: 'Returns Orders',
    vi: 'Phiếu trả hàng'
  },
  Report: {
    en: 'Report',
    vi: 'Báo cáo'
  },
  Revenue: {
    en: 'Revenue',
    vi: 'Doanh thu'
  },
  ProfitMargin: {
    en: 'Profit Margin',
    vi: 'Tỷ suất lợi nhuận'
  },
  TheSales: {
    en: 'Sales',
    vi: 'Doanh số'
  },
  Products: {
    en: 'Products',
    vi: 'Sản phẩm'
  },
  Product: {
    en: 'Product',
    vi: 'Sản phẩm'
  },
  SupplierId: {
    en: 'Supplier Id',
    vi: 'Mã NCC'
  },
  Supplier: {
    en: 'Supplier',
    vi: 'Nhà cung cấp'
  },
  TransferShipment: {
    en: 'Transfer Shipment',
    vi: 'Điều chuyển'
  },
  InventoryAssets: {
    en: 'Inventory Assets',
    vi: 'Tồn kho'
  },
  PhysicalChanges: {
    en: 'Physical Changes',
    vi: 'Thay đổi vật lí'
  },
  Reason: {
    en: 'Reason',
    vi: 'Nguyên nhân'
  },
  quantityChange: {
    en: 'Quantity Change',
    vi: 'SL thay đổi'
  },
  comments: {
    en: 'comments',
    vi: 'Ghi chú'
  },
  Back: {
    en: 'Back',
    vi: 'Trở lại'
  },
  Detail: {
    en: 'Detail',
    vi: 'Chi tiết'
  },
  Add: {
    en: 'Add',
    vi: 'Thêm'
  },
  Save: {
    en: 'Save',
    vi: 'Lưu'
  },
  Close: {
    en: 'Close',
    vi: 'Đóng'
  },
  Begin: {
    en: 'Begin',
    vi: 'Bắt đầu'
  },
  Cancel: {
    en: 'Cancel',
    vi: 'Hủy bỏ'
  },
  Create: {
    en: 'Create',
    vi: 'Tạo mới'
  },
  CreateAndContinue: {
    en: 'Create And Continue',
    vi: 'Tạo mới và tiếp tục'
  },
  Continue: {
    en: 'Continue',
    vi: 'Tiếp tục'
  },
  Edit: {
    en: 'Edit',
    vi: 'Chỉnh sửa'
  },
  Update: {
    en: 'Update',
    vi: 'Cập nhật'
  },
  Delete: {
    en: 'Delete',
    vi: 'Xóa'
  },
  Lock: {
    en: 'Lock',
    vi: 'Khóa'
  },
  Unlock: {
    en: 'Unlock',
    vi: 'Mở khóa'
  },
  productId: {
    en: 'Product Id',
    vi: 'Mã sản phẩm'
  },
  MaterialId: {
    en: 'Material Id',
    vi: 'Mã NVL'
  },
  productName: {
    en: 'Product Name',
    vi: 'Tên sản phẩm'
  },
  MaterialName: {
    en: 'Material Name',
    vi: 'Tên NVL'
  },
  description: {
    en: 'Description',
    vi: 'Mô tả'
  },
  status: {
    en: 'Status',
    vi: 'Trạng thái'
  },
  tikiStatus: {
    en: 'Tiki Status',
    vi: 'Trạng thái Tiki'
  },
  ReturnStatus: {
    en: 'Return Status',
    vi: 'Trạng thái hoàn'
  },
  FieldIsRequired: {
    en: 'Field is required.',
    vi: 'Trường không được để trống.'
  },
  ContactNumberInvalid: {
    en: 'Contact number invalid.',
    vi: 'Số điện thoại không hợp lệ.'
  },
  contactNumber: {
    en: 'Contact Number',
    vi: 'Số điện thoại'
  },
  Search: {
    en: 'Search',
    vi: 'Tìm kiếm'
  },
  SearchProduct: {
    en: 'Search Product',
    vi: 'Tìm sản phẩm'
  },
  SearchCustomer: {
    en: 'Search Customer',
    vi: 'Tìm khách hàng'
  },
  unitListPrice: {
    en: 'Unit List Price',
    vi: 'Giá niêm yết'
  },
  unitAmount: {
    en: 'Unit Amount',
    vi: 'Đơn giá'
  },
  qty: {
    en: 'Qty',
    vi: 'SL'
  },
  quantity: {
    en: 'Quantity',
    vi: 'Số lượng'
  },
  Quantity: {
    en: 'Quantity',
    vi: 'Số lượng'
  },
  quantityNotBilled: {
    en: 'Not Billed',
    vi: 'Chưa thanh toán'
  },
  quantityNotShipped: {
    en: 'Not Shipped',
    vi: 'Chưa giao'
  },
  quantityShipped: {
    en: 'Shipped',
    vi: 'Đã giao'
  },
  hasShipment: {
    en: 'Has Shipment',
    vi: 'Có DO'
  },
  itemTotal: {
    en: 'Item Total',
    vi: 'Thành tiền'
  },
  Information: {
    en: 'Information',
    vi: 'Thông tin'
  },
  facility: {
    en: 'Facility',
    vi: 'Kho hàng'
  },
  FacilityType: {
    en: 'Facility Type',
    vi: 'Loại kho'
  },
  ProductStore: {
    en: 'Product Store',
    vi: 'Cửa hàng'
  },
  storeName: {
    en: 'Store name',
    vi: 'Tên cửa hàng'
  },
  quantityOnHandTotal: {
    en: 'QOH',
    vi: 'QOH'
  },
  QOHDiff: {
    en: 'QOH Diff',
    vi: 'QOH thay đổi'
  },
  availableToPromiseTotal: {
    en: 'ATP',
    vi: 'ATP'
  },
  ATPDiff: {
    en: 'ATP Diff',
    vi: 'ATP thay đổi'
  },
  NotAvailable: {
    en: 'Not Available',
    vi: 'Không có sẵn'
  },
  NotIssued: {
    en: 'Not Issued',
    vi: 'Chưa xuất'
  },
  Source: {
    en: 'Source',
    vi: 'Nguồn'
  },
  Total: {
    en: 'Total',
    vi: 'Tổng'
  },
  grandTotal: {
    en: 'Grand Total',
    vi: 'Tổng cộng'
  },
  feeTotal: {
    en: 'Fee Total',
    vi: 'Tổng phí'
  },
  fee: {
    en: 'Fee',
    vi: 'Phí'
  },
  Profit: {
    en: 'Profit',
    vi: 'Lợi nhuận'
  },
  quantityTotal: {
    en: 'Quantity Total',
    vi: 'Tổng số'
  },
  pseudoId: {
    en: 'Pseudo Id',
    vi: 'Mã'
  },
  placedDate: {
    en: 'Placed Date',
    vi: 'Ngày đặt'
  },
  facilityName: {
    en: 'Facility Name',
    vi: 'Tên kho'
  },
  Geo: {
    en: 'Geo',
    vi: 'Địa phương'
  },
  Address: {
    en: 'Address',
    vi: 'Địa chỉ'
  },
  sequenceNum: {
    en: 'SequenceNum',
    vi: 'Số thứ tự'
  },
  SupplierNotFound: {
    en: 'Supplier Not Found',
    vi: 'Không tìm thấy nhà cung cấp'
  },
  PriceNotFound: {
    en: 'Price Not Found',
    vi: 'Không tìm thấy giá sản phẩm'
  },
  Picklist: {
    en: 'Picklist',
    vi: 'Soạn hàng'
  },
  OutgoingShipment: {
    en: 'Outgoing Shipment',
    vi: 'Xuất hàng'
  },
  IssueProduct: {
    en: 'Issue item',
    vi: 'Xuất hàng'
  },
  OutgoingShipmentList: {
    en: 'Outgoing Shipment List',
    vi: 'Danh sách Phiếu Xuất'
  },
  IncomingShipment: {
    en: 'Incoming Shipment',
    vi: 'Nhập hàng'
  },
  IncomingShipmentList: {
    en: 'Incoming Shipment List',
    vi: 'Danh sách Phiếu Nhập'
  },
  Content: {
    en: 'Content',
    vi: 'Nội dung'
  },
  Associated: {
    en: 'Associated',
    vi: 'Liên kết'
  },
  Categories: {
    en: 'Categories',
    vi: 'Danh mục'
  },
  Price: {
    en: 'Price',
    vi: 'Giá'
  },
  Identification: {
    en: 'Identification',
    vi: 'Mã vạch'
  },
  Features: {
    en: 'Features',
    vi: 'Đặc điểm'
  },
  Brand: {
    en: 'Brand',
    vi: 'Thương hiệu'
  },
  ProductImage: {
    en: 'Product Image',
    vi: 'Ảnh sản phẩm'
  },
  ProductImageDetail: {
    en: 'Product Image Detail',
    vi: 'Album ảnh sản phẩm'
  },
  Placed: {
    en: 'Placed',
    vi: 'Đã lập'
  },
  Shipped: {
    en: 'Shipped',
    vi: 'Đã vận chuyển'
  },
  Delivered: {
    en: 'Delivered',
    vi: 'Đã giao'
  },
  Approved: {
    en: 'Approved',
    vi: 'Đã duyệt'
  },
  Approve: {
    en: 'Approve',
    vi: 'Duyệt'
  },
  UnApprove: {
    en: 'UnApprove',
    vi: 'Hủy duyệt'
  },
  Sent: {
    en: 'Sent',
    vi: 'Đã gửi'
  },
  Completed: {
    en: 'Completed',
    vi: 'Hoàn thành'
  },
  DateTime: {
    en: 'DateTime',
    vi: 'Thời gian'
  },
  Employee: {
    en: 'Employee',
    vi: 'Nhân viên'
  },
  OriginFacility: {
    en: 'Origin Facility',
    vi: 'Kho chuyển'
  },
  DestinationFacility: {
    en: 'Destination Facility',
    vi: 'Kho nhận'
  },
  Phone: {
    en: 'Phone',
    vi: 'Điện thoại'
  },
  Email: {
    en: 'Email',
    vi: 'Email'
  },
  CustomerClass: {
    en: 'Customer Class',
    vi: 'Loại khách hàng'
  },
  FullName: {
    en: 'Full Name',
    vi: 'Họ và tên'
  },
  birthDate: {
    en: 'BirthDate',
    vi: 'Sinh nhật'
  },
  gender: {
    en: 'Gender',
    vi: 'Giới tính'
  },
  ShippingAddress: {
    en: 'Shipping Address',
    vi: 'Địa chỉ giao hàng'
  },
  address1: {
    en: 'Address',
    vi: 'Số nhà, đường'
  },
  stateProvinceGeoId: {
    en: 'State Province',
    vi: 'Tỉnh/thành phố'
  },
  countyGeoId: {
    en: 'County',
    vi: 'Quận/huyện'
  },
  PostalTrust: {
    en: 'Postal Trust',
    vi: 'Tin cậy'
  },
  toName: {
    en: 'To Name',
    vi: 'Người nhận'
  },
  Select: {
    en: 'Select',
    vi: 'Chọn'
  },
  SelectAll: {
    en: 'Select All',
    vi: 'Chọn toàn bộ'
  },
  UnSelectAll: {
    en: 'UnSelect All',
    vi: 'Bỏ chọn toàn bộ'
  },
  receivedDate: {
    en: 'Received Date',
    vi: 'Ngày nhận'
  },
  pickupDate: {
    en: 'Pickup Date',
    vi: 'Ngày lấy hàng'
  },
  FromShipment: {
    en: 'From Shipment',
    vi: 'Từ mã nhập'
  },
  FromOrder: {
    en: 'From Order',
    vi: 'Từ đơn mua'
  },
  FromReturn: {
    en: 'From Return',
    vi: 'Từ đơn hoàn'
  },
  acquireCost: {
    en: 'Acquire Cost',
    vi: 'Chi phí mua'
  },
  Reservation: {
    en: 'Reservation',
    vi: 'Gom hàng'
  },
  Date: {
    en: 'Date',
    vi: 'Ngày'
  },
  estimatedReadyDate: {
    en: 'Estimated Ready Date',
    vi: 'Ngày dự kiến'
  },
  AssetDetailHistory: {
    en: 'Asset Detail History',
    vi: 'Lịch sử xuất nhập'
  },
  Receipt: {
    en: 'Receipt',
    vi: 'Phiếu Thu'
  },
  Issuance: {
    en: 'Issuance',
    vi: 'Phiếu xuất'
  },
  ReservationRemoved: {
    en: 'Reservation Removed',
    vi: 'Gom hàng đã hủy'
  },
  MoveFromAsset: {
    en: 'Move From Asset',
    vi: 'Chuyển từ tồn kho'
  },
  ConsumerPrices: {
    en: 'Consumer Prices',
    vi: 'Giá bán'
  },
  SupplierPrices: {
    en: 'Supplier Prices',
    vi: 'Giá mua'
  },
  ShipMethod: {
    en: 'Ship Method',
    vi: 'PT giao hàng'
  },
  Ship: {
    en: 'Ship',
    vi: 'Giao hàng'
  },
  SalesChannel: {
    en: 'Sales Channel',
    vi: 'Kênh bán hàng'
  },
  Confirm: {
    en: 'Confirm',
    vi: 'Xác nhận'
  },
  WaitForConfirm: {
    en: 'Wait for confirm',
    vi: 'Cần xác nhận'
  },
  PrintDeliveryNote: {
    en: 'Print delivery note',
    vi: 'In phiếu giao hàng'
  },
  OrderId: {
    en: 'Order Id',
    vi: 'Mã đơn hàng'
  },
  externalId: {
    en: 'External Id',
    vi: 'Mã của sàn'
  },
  SendOrReturnItems: {
    en: 'Send / Return items',
    vi: 'Gửi / trả hàng'
  },
  ConfirmationDate: {
    en: 'Confirmation Date',
    vi: 'Ngày xác nhận'
  },
  InventoryRequisitionId: {
    en: 'Inventory Requisition Id',
    vi: 'Phiếu gửi'
  },
  Print: {
    en: 'Print',
    vi: 'In'
  },
  MergePage: {
    en: 'Merge Page',
    vi: 'Gộp trang'
  },
  PrintMinCode: {
    en: 'Print MinCode',
    vi: 'In MinCode'
  },
  Deadline: {
    en: 'Deadline',
    vi: 'Hạn chót'
  },
  ConfirmAll: {
    en: 'Confirm All',
    vi: 'Xác nhận tất cả'
  },
  AllOrdersAreConfirmed: {
    en: 'All Orders Are Confirmed',
    vi: 'Tất cả đơn đã Xác nhận'
  },
  Organization: {
    en: 'Organization',
    vi: 'Tổ chức'
  },
  Synchronization: {
    en: 'Synchronization',
    vi: 'Đồng bộ'
  },
  Sync: {
    en: 'Sync',
    vi: 'Đồng bộ'
  },
  Username: {
    en: 'Username',
    vi: 'Tài khoản'
  },
  Password: {
    en: 'Password',
    vi: 'Mật khẩu'
  },
  IncludeFacility: {
    en: 'Include Facility',
    vi: 'Bao gồm kho hàng'
  },
  newPassword: {
    en: 'New Password',
    vi: 'Mật khẩu mới'
  },
  newPasswordVerify: {
    en: 'New Password Verify',
    vi: 'Nhập lại mật khẩu mới'
  },
  ChangePassword: {
    en: 'Change Password',
    vi: 'Đổi mật khẩu'
  },
  cronExpressionSync: {
    en: 'Cron Expression Sync',
    vi: 'Chu kỳ đồng bộ'
  },
  cronExpressionConfirm: {
    en: 'Cron Expression Confirm',
    vi: 'Chu kỳ xác nhận'
  },
  All: {
    en: 'All',
    vi: 'Tất cả'
  },
  InPeriod: {
    en: 'In Period',
    vi: 'Trong khoảng thời gian'
  },
  AllTime: {
    en: 'All time',
    vi: 'Toàn thời gian'
  },
  Last30days: {
    en: 'Last 30 days',
    vi: '30 ngày qua'
  },
  Last7days: {
    en: 'Last 7 days',
    vi: '7 ngày qua'
  },
  Today: {
    en: 'Today',
    vi: 'Hôm nay'
  },
  ThisMonth: {
    en: 'This month',
    vi: 'Tháng này'
  },
  ProductError: {
    en: 'Product Error',
    vi: 'Sản phẩm lỗi'
  },
  MatchingProduct: {
    en: 'Matching Product',
    vi: 'Gán sản phẩm'
  },
  CreateComboProduct: {
    en: 'Create Combo Product',
    vi: 'Tạo Combo'
  },
  ComboInvalid: {
    en: 'Combo Invalid',
    vi: 'Combo không hợp lệ'
  },
  Ordered: {
    en: 'Ordered',
    vi: 'Đã đặt'
  },
  Received: {
    en: 'Received',
    vi: 'Đã nhận'
  },
  Scheduled: {
    en: 'Scheduled',
    vi: 'Lên kế hoạch'
  },
  Receive: {
    en: 'Receive',
    vi: 'Nhận'
  },
  Issued: {
    en: 'Issued',
    vi: 'Đã xuất'
  },
  Issue: {
    en: 'Issue',
    vi: 'Xuất'
  },
  ForceIssue: {
    en: 'Force Issue',
    vi: 'Vẫn xuất'
  },
  ReceiveEntire: {
    en: 'Receive Entire',
    vi: 'Nhận toàn bộ'
  },
  PackEntire: {
    en: 'Pack Entire',
    vi: 'Xuất toàn bộ'
  },
  quantityAcceptedTotal: {
    en: 'Quantity Accepted',
    vi: 'SL đã nhận'
  },
  quantityRemaining: {
    en: 'Quantity Remaining',
    vi: 'SL còn lại'
  },
  Remaining: {
    en: 'Remaining',
    vi: 'Còn lại'
  },
  quantityPackedOrIssued: {
    en: 'Quantity PackedOrIssued',
    vi: 'SL đã xuất'
  },
  minQuantity: {
    en: 'Min Quantity',
    vi: 'SL tối thiểu'
  },
  damagedQuantity: {
    en: 'Damaged Quantity',
    vi: 'SL hỏng'
  },
  fromDate: {
    en: 'From Date',
    vi: 'Từ ngày'
  },
  EffectiveDate: {
    en: 'Effective Date',
    vi: 'Ngày hiệu lực'
  },
  thruDate: {
    en: 'Thru Date',
    vi: 'Đến ngày'
  },
  NotPacked: {
    en: 'Not packed',
    vi: 'Chưa đóng hàng'
  },
  Packed: {
    en: 'Packed',
    vi: 'Đã đóng hàng'
  },
  Requisitions: {
    en: 'Requisitions',
    vi: 'Phiếu gửi'
  },
  Code: {
    en: 'Code',
    vi: 'Mã'
  },
  RefusedRequisitions: {
    en: 'Refused Requisitions',
    vi: 'Phiếu gửi bị từ chối'
  },
  Returns: {
    en: 'Returns',
    vi: 'Hàng hoàn'
  },
  expectedQuantity: {
    en: 'Expected',
    vi: 'Đặt'
  },
  receivedQuantity: {
    en: 'Received',
    vi: 'Nhận'
  },
  actualQuantity: {
    en: 'Actual Quantity',
    vi: 'SL thực tế'
  },
  acceptedQuantity: {
    en: 'Accepted',
    vi: 'Chấp nhận'
  },
  rejectedQuantity: {
    en: 'Rejected',
    vi: 'Từ chối'
  },
  note: {
    en: 'note',
    vi: 'Ghi chú'
  },
  ReturnableOrder: {
    en: 'Returnable Order',
    vi: 'Đơn hàng có thể trả lại'
  },
  ReturnResponse: {
    en: 'Return Response',
    vi: 'Đền bù'
  },
  returnPrice: {
    en: 'Return Price',
    vi: 'Tiền hoàn'
  },
  inventoryNotAvailable: {
    en: 'Inventory NA',
    vi: 'Thiếu hàng'
  },
  TypeEnum: {
    en: 'Type Enum',
    vi: 'Loại'
  },
  History: {
    en: 'History',
    vi: 'Lịch sử'
  },
  Service: {
    en: 'Service',
    vi: 'Dịch vụ'
  },
  Waiting: {
    en: 'Waiting',
    vi: 'Đang chờ'
  },
  Accounting: {
    en: 'Accounting',
    vi: 'Kế toán'
  },
  Finance: {
    en: 'Finance',
    vi: 'Tài chính'
  },
  Payment: {
    en: 'Payment',
    vi: 'Phiếu chi'
  },
  PaymentInstrument: {
    en: 'Payment Instrument',
    vi: 'PT Thanh toán'
  },
  Paid: {
    en: 'Paid',
    vi: 'Đã thanh toán'
  },
  Unpaid: {
    en: 'Unpaid',
    vi: 'Chưa thanh toán'
  },
  invoiceId: {
    en: 'Invoice Id',
    vi: 'Mã hóa đơn'
  },
  InvoiceNumber: {
    en: 'Invoice Number',
    vi: 'Số hóa đơn'
  },
  grossTotal: {
    en: 'Gross Total',
    vi: 'Số tiền'
  },
  invoiceTotal: {
    en: 'Invoice Total',
    vi: 'Số tiền'
  },
  Amount: {
    en: 'Amount',
    vi: 'Số tiền'
  },
  income: {
    en: 'Income',
    vi: 'Nhận về'
  },
  unpaidTotal: {
    en: 'Unpaid Total',
    vi: 'Còn thiếu'
  },
  statementCode: {
    en: 'Statement Code',
    vi: 'Mã sao kê'
  },
  statementDate: {
    en: 'Statement Date',
    vi: 'Ngày sao kê'
  },
  Download: {
    en: 'Download',
    vi: 'Tải xuống'
  },
  LabelPrinting: {
    en: 'Label Printing',
    vi: 'Tem phụ'
  },
  LostGoods: {
    en: 'Lost Goods',
    vi: 'Hàng thất lạc'
  },
  Diff: {
    en: 'Diff',
    vi: 'Chênh lệch'
  },
  List: {
    en: 'List',
    vi: 'Danh sách'
  },
  ProductDimension: {
    en: 'Product Dimension',
    vi: 'Kích thước SP'
  },
  Height: {
    en: 'Height',
    vi: 'Cao'
  },
  Width: {
    en: 'Width',
    vi: 'Rộng'
  },
  Depth: {
    en: 'Depth',
    vi: 'Sâu'
  },
  Weight: {
    en: 'Weight',
    vi: 'Nặng'
  },
  Pull: {
    en: 'Pull',
    vi: 'Đồng bộ về'
  },
  Push: {
    en: 'Push',
    vi: 'Đồng bộ lên'
  },
  AccountType: {
    en: 'Account Type',
    vi: 'Loại tài khoản'
  },
  ShippingFeeCustomer: {
    en: 'Shipping Fee Customer',
    vi: 'Phí vận chuyển KH'
  },
  ShippingFee: {
    en: 'Shipping Fee',
    vi: 'Phí vận chuyển'
  },
  Plan: {
    en: 'Plan',
    vi: 'Kế hoạch'
  },
  Average: {
    en: 'Average',
    vi: 'Trung bình'
  },
  Overviews: {
    en: 'Overviews',
    vi: 'Tổng quan'
  },
  Wallet: {
    en: 'Wallet',
    vi: 'Số dư ví'
  },
  TrackingNumber: {
    en: 'Tracking Number',
    vi: 'Mã vận đơn'
  },
  SupplierLiabilities: {
    en: 'Supplier Liabilities',
    vi: 'Công nợ NCC'
  },
  Summary: {
    en: 'Summary',
    vi: 'Tổng hợp'
  },
  Minimum: {
    en: 'Minimum',
    vi: 'Tối thiểu'
  },
  MinimumStock: {
    en: 'Minimum Stock',
    vi: 'Tồn kho tối thiểu'
  },
  Manufacturing: {
    en: 'Manufacturing',
    vi: 'Sản xuất'
  },
  ListProduct: {
    en: 'Product List',
    vi: 'Danh sách sản phẩm'
  },
  ListCategory: {
    en: 'Categories List',
    vi: 'Danh sách danh mục'
  },
  ListOrders: {
    en: 'Orders List',
    vi: 'Danh sách đơn hàng'
  },
  ListIssues: {
    en: 'Issues List',
    vi: 'Danh sách phiếu xuất'
  },
  categoryId: {
    en: 'Category ID',
    vi: 'Mã danh mục'
  },
  categoryName: {
    en: 'Category Name',
    vi: 'Tên danh mục'
  },
  Settlement: {
    en: 'Settlement',
    vi: 'Quyết toán',
  },
  CustomerDebt: {
    en: 'Customer Debt',
    vi: 'Công nợ khách hàng',
  },
  ProviderDebt: {
    en: 'Provider Debt',
    vi: 'Công nợ NCC',
  },
  OriginalPrice: {
    en: 'Original Price',
    vi: 'Giá vốn sản phẩm',
  },
  BankBook: {
    en: 'Bank Book',
    vi: 'Sổ ngân hàng',
  },
  CashBook: {
    en: 'Cash Book',
    vi: 'Sổ quỹ',
  },
  AdvancePayment: {
    en: 'Advance Payment',
    vi: 'Tạm ứng',
  },
  Norms: {
    en: 'Norms',
    vi: 'Định mức tồn kho',
  },
  DOMaking: {
    en: 'Making DO',
    vi: 'Kế hoạch xuất hàng',
  },
  FromSO: {
    en: 'From SO',
    vi: 'Từ đơn hàng',
  },
  FromSOList: {
    en: 'From SO List',
    vi: 'Từ danh sách đơn hàng',
  },
  Find: {
    en: 'Find',
    vi: 'Tìm kiếm',
  },
  MakeDOfromSOs: {
    en: 'Make DO from SOs',
    vi: 'Tạo DO từ các đơn đã chọn',
  },
  Warranty: {
    en: 'Warranty',
    vi: 'Bảo hành',
  },
  Provider: {
    en: 'Provider',
    vi: 'Nhà cung cấp',
  },
  ProviderList: {
    en: 'Provider List',
    vi: 'Danh sách NCC',
  },
  PurchasingOrderPlan: {
    en: 'Purchasing Order Plan',
    vi: 'Kế hoạch mua hàng',
  },
  PlanOrderList: {
    en: 'Order Plan List',
    vi: 'Danh sách kế hoạch',
  },
  CreatePlanOrder: {
    en: 'Create Plan Order',
    vi: 'Tạo kế hoạch',
  },
  Outgoing: {
    en: 'Outgoing',
    vi: 'Xuất',
  },
  Incoming: {
    en: 'Incoming',
    vi: 'Nhập',
  },
  ListWarranty: {
    en: 'Warranty List',
    vi: 'Danh sách bảo hành',
  },
  CustomerName: {
    en: 'Customer Name',
    vi: 'Tên khách hàng',
  },
  CustomerPhone: {
    en: 'Customer Phone',
    vi: 'Số điện thoại',
  },
  CustomerAddress: {
    en: 'Customer Address',
    vi: 'Địa chỉ khách hàng',
  },
  DOwithoutCM: {
    en: 'DO Without CM',
    vi: 'DO chưa có lệnh sản suất',
  },
  MakeCommand: {
    en: 'Make Command',
    vi: 'Tạo lệnh sản xuất',
  },
  ReportIO: {
    en: 'Report Income/Outgoing',
    vi: 'Báo cáo xuất nhập tồn',
  },
  Employee1: {
    en: 'Employee',
    vi: 'Nhân viên phụ trách',
  },
  EnterByEmployee: {
    en: 'Entered by employee',
    vi: 'Nhân viên tạo đơn',
  },
  stock: {
    en: 'Stock',
    vi: 'Tồn kho',
  },
  DOList: {
    en: 'DO List',
    vi: 'Phiếu xuất hàng',
  },
  DateTimeDO: {
    en: 'Planned Time',
    vi: 'Thời gian dự kiến giao',
  },
  CommandsPO: {
  en: 'Manufacturing Command',
    vi: 'Lệnh sản xuất',
},
  delivered: {
    en: 'Delivered',
    vi: 'Đã giao',
  },
  notdelivered: {
    en: 'Not Delivered',
    vi: 'Chưa giao',
  },
  ordervalue: {
    en: 'Order Value',
    vi: 'Giá trị đơn hàng',
  },
  orderId: {
    en: 'Order Id',
    vi: 'Mã đơn hàng',
  },
  doId: {
    en: 'DO Id',
    vi: 'Mã DO',
  },
  CreateOutgoingShipment: {
    en: 'Create Outgoing',
    vi: 'Tạo phiếu xuất',
  },
  DOwithoutOS: {
    en: 'DO without OS',
    vi: 'DO chưa có phiếu xuất',
  },
  MakeOS: {
    en: 'Make OS',
    vi: 'Tạo phiếu xuất',
  },
  InventoryCal: {
    en: 'Inventory Cal',
    vi: 'Dự kiến xuất bán',
  },
  productStockTmp: {
    en: 'Temp Stock',
    vi: 'Có thể xuất bán',
  },
  quantityDO: {
    en: 'DO Quantity',
    vi: 'Số lượng muốn lên DO',
  },
  TheSalesofDay: {
    en: 'The Sales of Day',
    vi: 'Doanh thu hôm nay',
  },
  TheSalesofMonth: {
    en: 'The Sales of Month',
    vi: 'Doanh thu tháng này',
  },
  SalesOrdersofDay: {
    en: 'Sales Orders of Day',
    vi: 'Đơn hàng hôm nay',
  },
  SalesOrdersofMonth: {
    en: 'Sales Orders of Month',
    vi: 'Đơn hàng tháng này',
  },
  productType: {
    en: 'Product Type',
    vi: 'Loại sản phẩm',
  },
  productStock: {
    en: 'Product Stock',
    vi: 'Tồn kho hiện tại',
  },
  productStatus: {
    en: 'Product Status',
    vi: 'Trạng thái',
  },
  DOneedCM: {
    en: 'DO need Manufacturing Command',
    vi: 'DO cần sản xuất',
  },
  Loan: {
    en: 'Loan',
    vi: 'Dòng vốn vay',
  },
  OrderAddress: {
    en: 'Order Address',
    vi: 'Khu Vực',
  },
  MakingOrder: {
    en: 'Making Order',
    vi: 'Tạo đơn hàng',
  },
  originalPrice: {
    en: 'Original Price',
    vi: 'Giá vốn',
  },
  price: {
    en: 'Price',
    vi: 'Giá bán',
  },
  FastDOMaking: {
    en: 'Fast DO Making',
    vi: 'Tạo nhanh DO',
  },
  Note: {
    en: 'Note',
    vi: 'Ghi chú',
  },
  deliveryDate: {
    en: 'Delivery Date',
    vi: 'Ngày giao dự kiến',
  },
  userName: {
    en: 'Username',
    vi: 'Tài khoản',
  },
  UserName: {
    en: 'User Name',
    vi: 'Tài khoản',
  },
  ResetPassword: {
    en: 'Reset Password',
    vi: 'Reset Mật khẩu',
  },
  User: {
    en: 'User',
    vi: 'Người dùng',
  },
  Name: {
    en: 'Name',
    vi: 'Tên',
  },
  CreateNewAccount: {
    en: 'Create New Account',
    vi: 'Tạo mới tài khoản',
  },
  Specifications: {
    en: 'Specifications',
    vi: 'Quy cách',
  },
  weight: {
    en: 'Weight',
    vi: 'Cân nặng',
  },
  length: {
    en: 'Length',
    vi: 'Dài',
  },
  width: {
    en: 'Width',
    vi: 'Rộng',
  },
  height: {
    en: 'Height',
    vi: 'Cao',
  },
  size: {
    en: 'Size',
    vi: 'Kích thước  (Dài x Rộng x Cao)',
  },
  'stock-and-purchasing-plan': {
    en: 'Stock and Purchasing Plan',
    vi: 'Tồn kho và Kế hoạch mua hàng',
  },
  current_stock: {
    en: 'Current Stock',
    vi: 'Tồn hiện tại',
  },
  GoodsInTransit: {
    en: 'In Transit',
    vi: 'Hàng đi đường'
  },
  planing_stock: {
    en: 'Planing Stock',
    vi: 'Tồn dự kiến',
  },
  tel: {
    en: 'Tel',
    vi: 'Số điện thoại',
  },
  providerAddress: {
    en: 'Provider Address',
    vi: 'Địa chỉ Nhà cung cấp',
  },
  providerName: {
    en: 'Provider Name',
    vi: 'Tên Nhà cung cấp',
  },
  SupplierName: {
    en: 'Supplier Name',
    vi: 'Tên NCC',
  },
  State: {
    en: 'State',
    vi: 'Quận',
  },
  City: {
    en: 'City',
    vi: 'Thành phố',
  },
  Country: {
    en: 'Country',
    vi: 'Quốc gia',
  },
  PurchasingOrder: {
    en: 'Purchasing Order',
    vi: 'Phiếu mua hàng',
  },
  organizationName: {
    en: 'Organization Name',
    vi: 'Tên tổ chức',
  },
  purchasingPrice: {
    en: 'Purchasing Price',
    vi: 'Giá mua',
  },
  ImportList: {
    en: 'Import List',
    vi: 'Danh sách phiếu nhập',
  },
  ImportOrder: {
    en: 'Import Order',
    vi: 'Phiếu nhập kho',
  },
  ExportList: {
    en: 'Export List',
    vi: 'Danh sách phiếu xuất',
  },
  ExportOrder: {
    en: 'Export Order',
    vi: 'Phiếu Xuất kho',
  },
  createdDate: {
    en: 'Created date',
    vi: 'Ngày tạo',
  },
  MakeMO: {
    en: 'Make MO',
    vi: 'Tạo lệnh sản xuất',
  },
  MOList: {
    en: 'MO List',
    vi: 'Lệnh sản xuất',
  },
  appliedDate: {
    en: 'Applied Date',
    vi: 'Áp dụng từ ngày',
  },
  dividends: {
    en: 'Dividends',
    vi: 'Chia cổ tức',
  },
  financialManagement: {
    en: 'Financial Management',
    vi: 'Quản lý tài chính',
  },
  businessAccounting: {
    en: 'Business Accounting',
    vi: 'Hạch toán kinh doanh',
  },
  estimatedCompletionDate: {
    en: 'Estimated Completion Date',
    vi: 'Ngày giao dự kiến',
  },
  estimatedDeliveryDate: {
    en: 'Estimated Date',
    vi: 'Dự kiến giao',
  },
  actualCompletionDate: {
    en: 'Actual Completion Date',
    vi: 'Ngày hoàn thành',
  },
  UpdateDO: {
    en: 'Update DO',
    vi: 'Cập nhật thông tin DO',
  },
  Overview: {
    en: 'Overview',
    vi: 'Báo cáo tổng hợp',
  },
  DomesticSupplier: {
    en: 'Domestic Supplier',
    vi: 'NCC trong nước',
  },
  ForeignSupplier: {
    en: 'Foreign Supplier',
    vi: 'NCC ngoài nước',
  },
  SupplierDebt: {
    en: 'Supplier Debt',
    vi: 'Công nợ NCC',
  },
  DetailSupplierDebt: {
    en: 'Detail Supplier Debt',
    vi: 'Chi tiết công nợ NCC',
  },
  OtherPayment: {
    en: 'Other Payment',
    vi: 'Phiếu thu khác',
  },
  PricePolicyId: {
    en: 'Price Policy Id',
    vi: 'Mã chính sách',
  },
  PricePolicyName: {
    en: 'Price Policy Name',
    vi: 'Tên chính sách',
  },
  PricePolicy: {
    en: 'Price Policy',
    vi: 'Chính sách',
  },
  CreatePricePolicy: {
    en: 'Create Price Policy',
    vi: 'Thêm chính sách',
  },
  UpdatePricePolicy: {
    en: 'Update Price Policy',
    vi: 'Chỉnh sửa chính sách',
  },
  ForParty: {
    en: 'For Party',
    vi: 'Đối tượng áp dụng',
  },
  GeneralInfo: {
    en: 'General info',
    vi: 'Thông tin chung'
  },
  AddProduct: {
    en: 'Add Product',
    vi: 'Thêm sản phẩm'
  },
  CurrencyUom: {
    en: 'Currency',
    vi: 'Tiền tệ'
  },
  Priority: {
    en: 'Priority',
    vi: 'Mức ưu tiên'
  },
  OutShipmentId: {
    en: 'Shipment Id',
    vi: 'Mã phiếu xuất'
  },
  InShipmentId: {
    en: 'Shipment Id',
    vi: 'Mã phiếu nhập'
  },
  inventoryHistories: {
    en: 'Inventory Histories',
    vi: 'Báo cáo xuất nhập tồn'
  },
  createNew: {
    en: 'Create',
    vi: 'Tạo mới'
  },
  list: {
    en: 'List',
    vi: 'Danh sách'
  },
  requireShipment: {
    en: 'Require Shipment',
    vi: 'Đơn cần tạo DO'
  },
  createDOForSelectedOrder: {
    en: 'Create DO For Selected Order',
    vi: 'Tạo DO cho đơn đã chọn'
  },
  produce: {
    en: 'Produce',
    vi: 'Sản xuất'
  },
  complete: {
    en: 'Complete',
    vi: 'Hoàn thành'
  },
  Choose: {
    en: 'Choose',
    vi: 'Chọn'
  },
  IssueDefectiveGoods: {
    en: 'Defective good handle',
    vi: 'Xuất hàng lỗi'
  },
  IssueDefectiveGoodsList: {
    en: 'Defective good handle',
    vi: 'Đợt xuất hàng lỗi'
  },
  IssueDefectiveCode: {
    en: 'Issue code',
    vi: 'Mã đợt xuất'
  },
  IssueFacility: {
    en: 'Issue facility',
    vi: 'Kho xuất'
  },
  ProductDefective: {
    en: 'Defective Product',
    vi: 'Sản phẩm lỗi, hỏng'
  },
  ReceiveExcessGoods: {
    en: 'Receive excess',
    vi: 'Nhập SP thừa'
  },
  ReceiveExcessGoodsList: {
    en: 'Receive excess handle',
    vi: 'Đợt nhập sản phẩm'
  },
  ReceiveExcessCode: {
    en: 'Receipt code',
    vi: 'Mã đợt nhập'
  },
  ReceiveFacility: {
    en: 'Receive facility',
    vi: 'Kho nhận'
  },
  ProductExcess: {
    en: 'Product excess',
    vi: 'Sản phẩm thừa'
  },
  FinishGoodsMade: {
    en: 'Finish goods made',
    vi: 'Thành phẩm nhập kho'
  },
  RawMaterialNeed: {
    en: 'Materials need',
    vi: 'Nguyên vật liệu sử dụng'
  },
  PdfDownload: {
    en: 'DownloadPdf',
    vi: 'Tải PDF'
  },
  FinGoodsUseMaterial: {
    en: 'Using by finished goods',
    vi: 'Thành phẩm sử dụng NVL'
  },
  FinGoodsId: {
    en: 'Finished goods id',
    vi: 'Mã thành phẩm'
  },
  FinGoodsName: {
    en: 'Finished goods',
    vi: 'Tên thành phẩm'
  },
  InvestedCapital: {
    en: 'Invested Capital',
    vi: 'Huy động vốn'
  },
  Type: {
    en: 'Type',
    vi: 'Loại'
  },
  Description: {
    en: 'Description',
    vi: 'Mô tả'
  },
  Transfer: {
    en: 'Transfer',
    vi: 'Điều chuyển'
  },
  CreateBatchDO: {
    en: 'Create Batch DO',
    vi: 'Tạo nhiều DO'
  },
  Customers: {
    en: 'Customers',
    vi: 'Khách hàng'
  },
  CategoryName: {
    en: 'Category Name',
    vi: 'Tên danh mục'
  },
  PricePolicyType: {
    en: 'Price Policy Type',
    vi: 'Loại chính sách giá'
  },
  Clone: {
    en: 'Clone',
    vi: 'Nhân bản'
  },
  AddCustomer: {
    en: 'Add Customer',
    vi: 'Thêm khách hàng'
  },
  AddPolicy: {
    en: 'Add Policy',
    vi: 'Thêm chính sách'
  },
  customerName: {
    en: 'Customer Name',
    vi: 'Tên khách hàng'
  },
  FixedAsset: {
    en: 'Fixed Asset',
    vi: 'Tài sản cố định'
  },
  shippingFee: {
    en: 'Shipping Fee',
    vi: 'Phí vận chuyển'
  },
  Shipping: {
    en: 'Shipping',
    vi: 'Vận chuyển'
  },
  otherFee: {
    en: 'Other Fee',
    vi: 'Phí khác'
  },
  otherFeeDesc: {
    en: 'Other Fee Description',
    vi: 'Ghi chú phí'
  },
  incomeSummary: {
    en: 'Income Summary',
    vi: 'Doanh thu trong kỳ'
  },
  outGoingSummary: {
    en: 'Outgoing Summary',
    vi: 'Chi trong kỳ'
  },
  productInventoryBeginning: {
    en: 'Product Inventory Beginning',
    vi: 'Tồn kho đầu kỳ'
  },
  supplierLiabilityBeginning: {
    en: 'Supplier Liability Beginning',
    vi: 'Công nợ NCC đầu kỳ'
  },
  customerLiabilityBeginning: {
    en: 'Customer Liability Beginning',
    vi: 'Công nợ KH đầu kỳ'
  },
  loanSummaryBeginning: {
    en: 'Loan Summary Beginning',
    vi: 'Vay đầu kỳ'
  },
  productInventoryEnding: {
    en: 'Product Inventory Ending',
    vi: 'Tồn kho cuối kỳ'
  },
  supplierLiabilityEnding: {
    en: 'Supplier Liability Ending',
    vi: 'Công nợ NCC cuối kỳ'
  },
  customerLiabilityEnding: {
    en: 'Customer Liability Ending',
    vi: 'Công nợ KH cuối kỳ'
  },
  loanSummaryEnding: {
    en: 'Loan Summary Ending',
    vi: 'Vay cuối kỳ'
  },
  deliveryOrderCount: {
    en: 'Delivery Order Count',
    vi: 'Tổng số DO trong kỳ'
  },
  deliveryOrderByDate: {
    en: 'Delivery Order By Date',
    vi: 'Số DO trong ngày'
  },
  deliveryOrderByMonth: {
    en: 'Delivery Order By Month',
    vi: 'Số DO trong tháng'
  },
  revenueSale: {
    en: 'Revenue',
    vi: 'Doanh số bán trong kỳ'
  },
  revenueSaleByDate: {
    en: 'Revenue By Date',
    vi: 'Doanh số bán trong ngày'
  },
  revenueSaleByMonth: {
    en: 'Revenue By Month',
    vi: 'Doanh số bán trong tháng'
  },
  shipmentCount: {
    en: 'Shipment Count',
    vi: 'Tổng phiếu nhập trong kỳ'
  },
  shipmentCountByMonth: {
    en: 'Shipment Count By Month',
    vi: 'Tổng phiếu nhập trong tháng'
  },
  revenuePurchase: {
    en: 'Revenue',
    vi: 'Tổng mua trong kỳ'
  },
  revenuePurchaseByMonth: {
    en: 'Revenue By Month',
    vi: 'Tổng mua trong tháng'
  },
  exportDate: {
    en: 'Export Date',
    vi: 'Ngày xuất'
  },
  importDate: {
    en: 'Import Date',
    vi: 'Ngày về'
  },
  VehicleOwner: {
    en: 'Vehicle Owner',
    vi: 'Nhà xe'
  },
  Driver: {
    en: 'Driver',
    vi: 'Lái xe'
  },
  Deliverable: {
    en: 'Deliverable',
    vi: 'Xe chở hàng'
  },
  employeeName: {
    en: 'Employee Name',
    vi: 'User tạo đơn'
  },
};
