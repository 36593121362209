import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';

import {Observable} from 'rxjs';

import {AuthService} from '../service/auth.service';
import {UserService} from '../service/user.service';
import {ModuleMenuService} from '../module-menu/module-menu.service';

import {AlertService} from '../service/alert.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService,
              private moduleService: ModuleMenuService,
              private alertService: AlertService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.moduleService.setModule(next);
    return this.checkLogin(state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.moduleService.setMenu(next, state);
    return this.checkLogin(state);
  }

  private checkLogin(state: RouterStateSnapshot): boolean {
    UserService.setRouterState(state.url);
    if (this.authService.getAuthorization()) {
      return true;
    } else {
      this.alertService.onError('Unauthorized').then(() => {
        this.router.navigateByUrl('/login').then();
      });
      return false;
    }
  }
}
