import {Component, OnInit} from '@angular/core';

import {environment} from '../../../environments/environment';

import {ModuleMenuModel as ModuleMenu} from '../../shared/module-menu/module-menu.model';

import {LeftSidebarService as Service} from './left-sidebar.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html'
})
export class LeftSidebarComponent implements OnInit {

  module: ModuleMenu;

  baseHref = environment.baseHref;

  constructor(private service: Service) {
  }

  ngOnInit() {
    this.service.getModule().subscribe((module) => {
      this.module = module;
    });
  }

}
