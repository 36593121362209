import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {LoginService} from './login.service';
import {UserService} from '../../shared/service/user.service';
import {AlertService} from '../../shared/service/alert.service';
import {LoginTokenService} from '../../entities/service/login.service';
import {HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../shared/service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  passwordChangeForm: FormGroup;
  token: string;
  submitted: boolean;
  options = {headers: new HttpHeaders({moquiSessionToken: this.authService.getAuthorization()})};

  requirePasswordChange: boolean;
  error: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService,
    private loginTokenService: LoginTokenService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });

    this.passwordChangeForm = this.fb.group({
      username: new FormControl('', Validators.required),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      newPasswordVerify: new FormControl('')
    });
    this.refreshToken();
  }

  refreshToken() {
    this.loginTokenService.get().subscribe((response) => {
      this.token = response.moquiSessionToken;
      this.authService.setAuthorization(response.moquiSessionToken);
      this.submitted = false;
    });
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      const options = {headers: new HttpHeaders({moquiSessionToken: this.token})};
      this.error = null;
      this.loginService.login(this.form.getRawValue(), options).subscribe((response) => {
        this.submitted = false;
        if (response && response.isSuccess === 'Y') {
          this.alertService.onSuccess().then(() => {
            this.authService.setAuthorization(response.moquiSessionToken);
            this.authService.setPrivilege().then(() => {
              let routerState = UserService.getRouterState();
              if (!routerState || routerState.indexOf('login') > -1) {
                routerState = '/Home/dashboard';
              }
              this.router.navigateByUrl(routerState).then();
            });
          });
        } else {
          if (response && response.errors && (response.errors[0].includes('[PWDCHG]') || response.errors[0].includes('[PWDTIM]'))) {
            this.refreshToken();
            this.passwordChangeForm.controls.username.setValue(this.form.controls.username.value);
            this.passwordChangeForm.controls.oldPassword.setValue(this.form.controls.password.value);
            this.requirePasswordChange = true;
            this.error = response.errors[0];
          } else if (response && response.errors && response.errors[0].includes('đã bị khóa')) {
            this.alertService.onError(response.errors[0]).then();
            this.refreshToken();
            this.submitted = false;
          } else {
            this.alertService.onError('UsernameOrPasswordIncorrect').then();
            this.refreshToken();
            this.submitted = false;
          }
        }
      }, error => {
        this.refreshToken();
      });
    }
  }

  onPasswordChange() {
    console.log('aaa');
    this.passwordChangeForm.controls.newPasswordVerify.setValue(this.passwordChangeForm.controls.newPassword.value);
    console.log(this.passwordChangeForm.getRawValue());
    const options = {headers: new HttpHeaders({moquiSessionToken: this.token})};
    this.loginService.changePassword(this.passwordChangeForm.getRawValue(), options).subscribe(res => {
      if (res.isSuccess === 'Y') {
        this.form.controls.password.setValue(this.passwordChangeForm.controls.newPassword.value);
        this.onSubmit();
        this.requirePasswordChange = false;
      } else {
        this.alertService.onError(res.messages).then();
      }
    }, error => {
    });
  }

}
