import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ModuleMenuModel as ModuleMenu} from '../../shared/module-menu/module-menu.model';

import {HeaderService as Service} from './header.service';
import {TranslateService} from '../../shared/translate/translate.service';
import {LoginService} from '../../pages/login/login.service';
import {HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../shared/service/auth.service';
import {NotificationsService} from '../../shared/service/notifications.service';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  locale: string;
  options;
  modules: ModuleMenu[];

  interval: any;

  count = 0;
  notifications: Array<any>;

  constructor(
    private router: Router,
    private service: Service,
    private translateService: TranslateService,
    private loginService: LoginService,
    private authService: AuthService,
    private notificationsService: NotificationsService
  ) {
  }

  ngOnInit() {
    this.locale = this.translateService.locale;

    this.service.getModules().subscribe((modules) => {
      this.modules = modules;
    });

    this.getCount();
     this.interval = setInterval(() => {
       this.getCount();
     }, 1000 * 100);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  getCount(): void {
    this.notificationsService.getCount().subscribe((response: any) => {
      this.count = response.number || 0;
    });
  }

  markView(notificationMessageId): void {
    const options = {headers: new HttpHeaders({ moquiSessionToken: this.authService.getAuthorization() })};
    this.notificationsService.markView(notificationMessageId, options).subscribe((response: any) => {
      // this.count = response.number || 0;
    });
  }

  loadNotifications(): void {
    this.notificationsService.getList().subscribe((response: any) => {
      this.notifications = response.notifications;
      this.getCount();
    });
  }

  setLocale(locale: string) {
    this.locale = locale;
    this.translateService.setLocale(locale);
  }

  logout() {
    this.options = {headers: new HttpHeaders({moquiSessionToken: this.authService.getAuthorization()})};
    this.loginService.logout(this.options).subscribe(() => {
      this.router.navigateByUrl('/login').then();
    });
  }
  goToLink(link: string, notificationMessageId: string, op: OverlayPanel) {
    this.markView(notificationMessageId);
    this.router.navigate([link]).then();
    op.hide();
  }
}
