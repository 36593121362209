import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {LoginService} from '../login/login.service';
import {UserService} from '../../shared/service/user.service';
import {AlertService} from '../../shared/service/alert.service';
import {HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../shared/service/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;

  options = {headers: new HttpHeaders({ moquiSessionToken: this.authService.getAuthorization() })};
  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      username: new FormControl('', Validators.required),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      newPasswordVerify: new FormControl('', Validators.required)
    });
    this.form.controls.username.patchValue(UserService.getUsername());
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      this.loginService.changePassword(this.form.getRawValue(), this.options).subscribe(() => {
        this.alertService.onSuccess().then(() => {
          let routerState = UserService.getRouterState();
          if (!routerState) {
            routerState = '/Home/dashboard';
          }
          this.router.navigateByUrl(routerState).then();
        });
      }, () => {
        this.submitted = false;
      });
    }
  }

}
