import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  link;

  constructor() {
    this.link = localStorage.getItem('link');
    /*
    this.linkPurchases = localStorage.getItem('linkPurchases');
    this.linkLogistic = localStorage.getItem('linkLogistic');
    this.linkManufacturing = localStorage.getItem('linkManufacturing');
    this.linkFinancial = localStorage.getItem('linkFinancial');
    this.linkAdmin = localStorage.getItem('linkAdmin');
    */
  }

  setLink(link) {
    if (link) {
      this.link = link;
      localStorage.setItem('link', link);
    }
  }

  /*

    setLinkPurchases(linkPurchases) {
      if (linkPurchases) {
        this.linkPurchases = linkPurchases;
        localStorage.setItem('linkPurchases', linkPurchases);
      }
    }

    setLinkLogistic(linkLogistic) {
      if (linkLogistic) {
        this.linkLogistic = linkLogistic;
        localStorage.setItem('linkLogistic', linkLogistic);
      }
    }

    setLinkManufacturing(linkManufacturing) {
      if (linkManufacturing) {
        this.linkManufacturing = linkManufacturing;
        localStorage.setItem('linkManufacturing', linkManufacturing);
      }
    }

    setLinkFinancial(linkFinancial) {
      if (linkFinancial) {
        this.linkFinancial = linkFinancial;
        localStorage.setItem('linkFinancial', linkFinancial);
      }
    }

    setLinkAdmin(linkAdmin) {
      if (linkAdmin) {
        this.linkAdmin = linkAdmin;
        localStorage.setItem('linkAdmin', linkAdmin);
      }
    }
  */

  getLink() {
    return this.link;
  }


  /*
  getLinkPurchases() {
    return this.linkPurchases;
  }

  getLinkLogistic() {
    return this.linkLogistic;
  }

  getLinkManufacturing() {
    return this.linkManufacturing;
  }

  getLinkFinancial() {
    return this.linkFinancial;
  }

  getLinkAdmin() {
    return this.linkAdmin;
  }*/

  removeLinks() {
    this.link = null;

    /*
    this.linkPurchases = null;
    this.linkLogistic = null;
    this.linkManufacturing = null;
    this.linkFinancial = null;
    this.linkAdmin = null;*/

    localStorage.removeItem('link');

    /*localStorage.removeItem('linkPurchases');
    localStorage.removeItem('linkLogistic');
    localStorage.removeItem('linkManufacturing');
    localStorage.removeItem('linkFinancial');
    localStorage.removeItem('linkAdmin');*/
  }
}
