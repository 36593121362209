import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html'
})
export class EntitiesComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    const node = document.createElement('script');
    node.src = 'assets/js/plugins.js';
    node.type = 'text/javascript';
    node.async = false;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
