import {Component, OnInit} from '@angular/core';

import {ModuleMenuModel as ModuleMenu} from '../../shared/module-menu/module-menu.model';

import {BreadcrumbService as Service} from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

  module: ModuleMenu;
  menu: ModuleMenu;
  action: string;

  linkDownload: string;
  routerLinkBack: string;
  routerLinkCreate: string;

  constructor(private service: Service) {
  }

  ngOnInit() {
    this.service.getModule().subscribe((module) => {
      this.module = module;
    });
    this.service.getMenu().subscribe((menu) => {
      this.menu = menu;
    });
    this.service.getAction().subscribe((action) => {
      this.action = action;
    });

    this.service.getLinkDownload().subscribe((linkDownload) => {
      this.linkDownload = linkDownload;
    });
    this.service.getRouterLinkBack().subscribe((routerLinkBack) => {
      this.routerLinkBack = routerLinkBack;
    });
    this.service.getRouterLinkCreate().subscribe((routerLinkCreate) => {
      this.routerLinkCreate = routerLinkCreate;
    });
  }

}
