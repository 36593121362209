import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) {
  }

  getCount() {
    return this.http.get(`dchn-common/notifications/count`);
  }

  markView(notificationMessageId: string, options): Observable<any> {
    return this.http.put(`dchn-common/notifications/${notificationMessageId}/mark-viewed`, {}, options);
  }

  getList() {
    return this.http.get(`dchn-common/notifications/list`);
  }

}
